<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 110.01 40.33"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M74.17,5.36,96.41,16.07,35.84,35,13.6,24.26,74.17,5.36M74.56,0,0,23.26,35.44,40.33,110,17.07,74.56,0Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #292828;
}
</style>
